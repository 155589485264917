// Copyright 2024, Grant Richardson
// License: GNU Affero General Public License v3.0 only

// You should have received a copy of the GNU Affero General Public License,
// version 3, along with this program.
// If not, see <https://www.gnu.org/licenses/agpl-3.0.html>.import React from "react";
const Message = ({ messageObj }) => {
  const visibility = messageObj.isVisible ? "visible" : "hidden";
  return (
    <div
      style={{ visibility: visibility }}
      className={
        visibility === "hidden"
          ? "messageHidden"
          : messageObj.passOrFail === "pass"
          ? "message messagePass"
          : "message messageFail"
      }
    >
      {messageObj.messageText !== "" && messageObj.messageText}
    </div>
  );
};
export default Message;
