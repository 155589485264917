// Copyright 2024, Grant Richardson
// License: GNU Affero General Public License v3.0 only

// You should have received a copy of the GNU Affero General Public License,
// version 3, along with this program.
// If not, see <https://www.gnu.org/licenses/agpl-3.0.html>.import React from "react";
const ProgressBar = ({ isVisible, useMobileStyle }) => {
  const visibility = isVisible ? "visible" : "hidden";
  return (
    <div style={{ visibility: visibility }} className={ useMobileStyle ? 'progressBar progressBarMobile' : 'progressBar' }>
      <div className='bar' ></div>
    </div>
  );
};

export default ProgressBar;
