// Copyright 2024, Grant Richardson
// License: GNU Affero General Public License v3.0 only

// You should have received a copy of the GNU Affero General Public License,
// version 3, along with this program.
// If not, see <https://www.gnu.org/licenses/agpl-3.0.html>.
import axios from "axios";
import BtnsTranslateAndSwap from "./components/BtnsTranslateAndSwap";
import Footer from "./components/Footer";
import FromLanguageWidget from "./components/FromLanguageWidget";
import Header from "./components/Header";
import Messages from "./components/Message";
import ProgressBar from "./components/ProgressBar";
import React from "react";
import ToLanguageWidget from "./components/ToLanguageWidget";
import { useState, useEffect, useRef } from "react";
import { getVoiceModelObjByLangCode, getSpeakerIdxOptions } from "./Utils";

// window.__ENV__ variables are configured in the file public/env.js,
// which allows them to be reconfigured post-build, e.g., in production.
const apiUrl = window.__ENV__.LT_API_PROXY_URL;
const ttsFeaturesEnabled = window.__ENV__.ENABLE_TTS_FEATURES;
const ttsApiUrl = window.__ENV__.TTS_API_URL;
let unsafeSubstrings = [];
if (window.__ENV__.UNSAFE_SUBSTRINGS) {
  unsafeSubstrings = window.__ENV__.UNSAFE_SUBSTRINGS;
}

function App() {
  const btnTranslateRef = useRef(null); // for auto-click in ToLanguageWidget
  const windowWidth = useRef(window.innerWidth);
  const isMobile = windowWidth.current < 864;
  const textareaRows = 14;
  const [availableLanguages, setAvailableLanguages] = useState([]);
  const [availableVoiceModels, setAvailableVoiceModels] = useState([]);
  const [toText, setToText] = useState("");
  const [isFetchingData, setIsFetchingData] = useState(false);
  const [userInputArgs, setUserInputArgs] = useState({
    fromLanguageObj: { value: "" }, // any default is set in FromLanguageWidget
    isInvalidFromLanguage: false,
    fromText: "",
    isInvalidFromText: false,
    toLanguageObj: { value: "" }, // any default is set in FromLanguageWidget
    isInvalidToLanguage: false,
  });
  const [selectedFromVoiceModelObj, setSelectedFromVoiceModelObj] = useState(
    {}
  );
  const [selectedFromSpkIdxObj, setSelectedFromSpkIdxObj] = useState({});
  const [selectedToVoiceModelObj, setSelectedToVoiceModelObj] = useState({});
  const [selectedToSpkIdxObj, setSelectedToSpkIdxObj] = useState({});
  const unavailableVoiceObj = {
    lang_code: "xx",
    language: "",
    model: "",
    speaker_idx_max: "",
    speaker_idx_default: "",
  };
  const [statusMessageObj, setStatusMessageObj] = useState({
    messageText: "",
    isVisible: false,
    passOrFail: "pass",
  });

  useEffect(() => {
    loadAvailableLanguages();
    ttsFeaturesEnabled && loadAvailableVoiceModels();
  }, []); // eslint-disable-line

  const loadAvailableLanguages = async () => {
    const languages = await fetchLanguages();
    if (languages) {
      setAvailableLanguages(
        languages.map((languageObj) => {
          return {
            value: languageObj.code,
            label: languageObj.name,
          };
        })
      );
    }
  };

  const loadAvailableVoiceModels = async () => {
    try {
      const voiceModels = await fetchVoiceModels();
      if (voiceModels) {
        // statusText exists in error responses
        if (voiceModels.statusText) {
          setStatusMessageObj({
            messageText:
              "Failed to fetch the list of voice models. Server response message: " +
              voiceModels.statusText,
            isVisible: true,
            passOrFail: "fail",
          });
        } else {
          setAvailableVoiceModels(
            voiceModels.data.map((voiceModelObj) => {
              return {
                lang_code: voiceModelObj.lang_code,
                language: voiceModelObj.language,
                model: voiceModelObj.model,
                speaker_idx_max: voiceModelObj.speaker_idx_max,
                speaker_idx_default: voiceModelObj.speaker_idx_default,
                speaker_array: voiceModelObj.speaker_array,
              };
            })
          );
        }
      }
    } catch (error) {
      setStatusMessageObj({
        messageText:
          "Failed to fetch the list of voice models! Ensure that the text-to-speech server is online, or disable TTS features.",
        isVisible: true,
        passOrFail: "fail",
      });
    }
  };

  const fetchLanguages = async () => {
    const timeoutInMs = 5000;
    try {
      const response = await axios.get(apiUrl + "/languages", {
        timeout: timeoutInMs,
      });
      return response.data;
    } catch (error) {
      setStatusMessageObj({
        messageText:
          "Failed to fetch the list of languages! " +
          updateErrorMsgWording(error.message),
        isVisible: true,
        passOrFail: "fail",
      });
    }
  };

  const fetchVoiceModels = async () => {
    const timeoutInMs = 5000;
    try {
      const response = await axios.get(ttsApiUrl + "/tts-models", {
        timeout: timeoutInMs,
      });
      return response.data;
    } catch (error) {
      setStatusMessageObj({
        messageText:
          "Failed to fetch the list of text-to-speech voice models. Server error: " +
          error.response.statusText,
        isVisible: true,
        passOrFail: "fail",
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateInputs(e)) {
      loadTranslation();
    }
  };

  const loadTranslation = async () => {
    setIsFetchingData(true);
    try {
      const response = await fetchTranslation();
      if (response.status >= 500) {
        setStatusMessageObj({
          messageText: updateErrorMsgWording(
            "Received error code " + response.status + " from the server."
          ),
          isVisible: true,
          passOrFail: "fail",
        });
        setIsFetchingData(false);
        return;
      }
      const data = await response.json();
      if (data.translatedText) {
        setToText(data.translatedText);
      } else {
        setStatusMessageObj({
          messageText: updateErrorMsgWording(data.error),
          isVisible: true,
          passOrFail: "fail",
        });
      }
    } catch (error) {
      setStatusMessageObj({
        messageText: updateErrorMsgWording(error),
        isVisible: true,
        passOrFail: "fail",
      });
    }
    setIsFetchingData(false);
  };

  // N.B., this front end doesn't handle LT keys, but the proxy server can use
  // one for guests and can handle keys in requests originating from other URLs.
  const fetchTranslation = async () => {
    const reqBody = {
      q: userInputArgs.fromText,
      source: userInputArgs.fromLanguageObj.value
        ? userInputArgs.fromLanguageObj.value
        : "auto",
      target: userInputArgs.toLanguageObj.value,
      format: "text",
      api_key: "",
    };
    const res = await fetch(apiUrl + "/translate", {
      method: "POST",
      body: JSON.stringify(reqBody),
      headers: { "Content-Type": "application/json" },
    });
    return res;
  };

  const updateErrorMsgWording = (errorMessage) => {
    errorMessage = errorMessage.toString().replace("TypeError: ", "");
    // Errors from requests to `languages` end point
    if (errorMessage.includes("timeout")) {
      errorMessage = "Gave gave up waiting for a response from the server.";
    }
    if (errorMessage.includes("Network Error")) {
      errorMessage =
        "Could not connect to the server. Please try again shortly.";
    }
    if (errorMessage.includes("Request failed with status code 502")) {
      errorMessage =
        "Received error code 502 from the server. The API proxy server (opentranslate.service) may be down.";
    }
    if (errorMessage.includes("Request failed with status code 500")) {
      errorMessage =
        "Received error code 500 from the server. The LibreTranslate API service may be offline.";
    }
    // Errors from requests to `translate` end point
    if (errorMessage.includes("Failed to fetch")) {
      errorMessage =
        "An error occured while fetching the translation! The service may currently be offline.";
    }
    if (errorMessage.includes("503")) {
      errorMessage =
        "Received error code 503 from the server (temporarily unavailable). Please try again in a moment.";
    }
    if (errorMessage.includes("unexpected character at line 1 column 1")) {
      errorMessage =
        "An unexpected error occured while requesting data. Please try again in a moment.";
    }
    return errorMessage;
  };

  // Run upon submit
  const validateInputs = (e) => {
    let isInvalidToLanguage = false;
    let isInvalidFromText = false;
    // Invalidate empty userInputArgs.fromText
    !userInputArgs.fromText && (isInvalidFromText = true);
    // Invalidate empty userInputArgs.toLanguageObj.value
    !userInputArgs.toLanguageObj.value && (isInvalidToLanguage = true);
    if (isInvalidToLanguage || isInvalidFromText) {
      setStatusMessageObj({
        messageText: "Please fill in all required fields",
        isVisible: true,
        passOrFail: "fail",
      });
    } else if (
      unsafeSubstrings.some((item) => userInputArgs.fromText.includes(item))
    ) {
      isInvalidFromText = true;
      setStatusMessageObj({
        messageText:
          'Translation request not sent. Input text contains one or more of these potentially unsafe substrings: "' +
          unsafeSubstrings.join('", "') +
          '"',
        isVisible: true,
        passOrFail: "fail",
      });
    } else {
      setStatusMessageObj({
        messageText: "",
        isVisible: false,
        passOrFail: "pass",
      });
    }
    setUserInputArgs({
      ...userInputArgs,
      isInvalidFromText: isInvalidFromText,
      isInvalidToLanguage: isInvalidToLanguage,
      elementClicked: e.target.id,
    });
    return !isInvalidToLanguage && !isInvalidFromText;
  };

  const handleSwapBtnClick = (e) => {
    e.preventDefault();
    const origFromLanguageObj = userInputArgs.fromLanguageObj;
    const origToLanguageObj = userInputArgs.toLanguageObj;
    const origFromText = userInputArgs.fromText;
    const newFromVoiceObj = getVoiceModelObjByLangCode(
      availableVoiceModels,
      userInputArgs.toLanguageObj.value
    );
    const newToVoiceObj = getVoiceModelObjByLangCode(
      availableVoiceModels,
      userInputArgs.fromLanguageObj.value
    );
    // Always swap the text input language selector values.
    // But, swap the textarea values only if toText has text.
    setUserInputArgs({
      ...userInputArgs,
      isInvalidFromText: false,
      isInvalidToLanguage: false,
      fromLanguageObj: userInputArgs.toLanguageObj,
      toLanguageObj: origFromLanguageObj,
      fromText: toText !== "" ? toText : origFromText,
      elementClicked: e.target.id,
    });
    setToText(toText !== "" ? origFromText : toText);
    setSelectedFromVoiceModelObj(
      newFromVoiceObj
        ? newFromVoiceObj
        : origToLanguageObj.value && unavailableVoiceObj
    );
    setSelectedToVoiceModelObj(
      newToVoiceObj
        ? newToVoiceObj
        : origFromLanguageObj.value && unavailableVoiceObj
    );
    setStatusMessageObj({
      messageText: "",
      isVisible: false,
      passOrFail: "pass",
    });
    let spkIdxObjects = getSpeakerIdxOptions(newFromVoiceObj);
    newFromVoiceObj && newFromVoiceObj.speaker_idx_default
      ? setSelectedFromSpkIdxObj(
          spkIdxObjects[newFromVoiceObj.speaker_idx_default]
        )
      : setSelectedFromSpkIdxObj({ value: 0 });
    spkIdxObjects = getSpeakerIdxOptions(newToVoiceObj);
    newToVoiceObj && newToVoiceObj.speaker_idx_default
      ? setSelectedToSpkIdxObj(spkIdxObjects[newToVoiceObj.speaker_idx_default])
      : setSelectedToSpkIdxObj({ value: 0 });
  };

  // Suppress onClick event handler for anchor tags. We use anchor tags only to
  // display URL in status bar on hover. For navigation, use handleLinkBtnClick.
  const handleAnchorTagClick = (e) => {
    e.preventDefault();
  };

  const handleLinkBtnClick = (url) => {
    if (userInputArgs.fromText) {
      if (
        window.confirm(
          "Do you really want to leave the page? Your input text may be lost upon returning here."
        )
      ) {
        goToUrl(url);
      }
      return;
    }
    goToUrl(url);
  };

  const goToUrl = (url) => {
    const a = document.createElement("a");
    a.rel = "noopener noreferrer";
    a.href = url;
    a.click();
  };

  // Log to the console each HTTP request's method and timestamp
  axios.interceptors.request.use(
    (config) => {
      console.log(
        `${config.method.toUpperCase()} request sent to ${
          config.url
        } at ${new Date().toISOString()}`
      );

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return (
    <>
      <Messages messageObj={statusMessageObj} />
      <div id='content-container'>
        <Header
          handleLinkBtnClick={handleLinkBtnClick}
          handleAnchorTagClick={handleAnchorTagClick}
        />
        <form>
          <div className={"form-inputs-container"}>
            <FromLanguageWidget
              availableLanguages={availableLanguages}
              userInputArgs={userInputArgs}
              setUserInputArgs={setUserInputArgs}
              textareaRows={textareaRows}
              // We pass the below props for the component's TTS features
              ttsFeaturesEnabled={ttsFeaturesEnabled}
              setIsFetchingData={setIsFetchingData}
              setStatusMessageObj={setStatusMessageObj}
              availableVoiceModels={availableVoiceModels}
              selectedFromVoiceModelObj={selectedFromVoiceModelObj}
              setSelectedFromVoiceModelObj={setSelectedFromVoiceModelObj}
              selectedFromSpkIdxObj={selectedFromSpkIdxObj}
              setSelectedFromSpkIdxObj={setSelectedFromSpkIdxObj}
              unavailableVoiceObj={unavailableVoiceObj}
            />
            <ProgressBar
              // Not visible on desktop screens
              isVisible={isFetchingData}
              useMobileStyle={isMobile}
            />
            <BtnsTranslateAndSwap
              // N.B. This is first of two copies of the TRANSLATE/SWAP buttons
              // This copy is not visible on desktop screens
              mobileOrDesktop='mobile'
              handleSubmit={handleSubmit}
              btnTranslateRef={btnTranslateRef}
              handleSwapBtnClick={handleSwapBtnClick}
              userInputArgs={userInputArgs}
              toText={toText}
            />
            <ToLanguageWidget
              availableLanguages={availableLanguages}
              userInputArgs={userInputArgs}
              setUserInputArgs={setUserInputArgs}
              toText={toText}
              setToText={setToText}
              textareaRows={textareaRows}
              btnTranslateRef={btnTranslateRef}
              // We pass the below props for the component's TTS features
              ttsFeaturesEnabled={ttsFeaturesEnabled}
              setIsFetchingData={setIsFetchingData}
              setStatusMessageObj={setStatusMessageObj}
              availableVoiceModels={availableVoiceModels}
              selectedToVoiceModelObj={selectedToVoiceModelObj}
              setSelectedToVoiceModelObj={setSelectedToVoiceModelObj}
              selectedToSpkIdxObj={selectedToSpkIdxObj}
              setSelectedToSpkIdxObj={setSelectedToSpkIdxObj}
              unavailableVoiceObj={unavailableVoiceObj}
            />
          </div>
          <ProgressBar
            // Not visible on mobile screens
            isVisible={isFetchingData}
            useMobileStyle={isMobile}
          />
          <BtnsTranslateAndSwap
            // N.B. This is second of two copies of the TRANSLATE/SWAP buttons
            // This copy is not visible on mobile screens
            mobileOrDesktop='desktop'
            handleSubmit={handleSubmit}
            handleSwapBtnClick={handleSwapBtnClick}
            userInputArgs={userInputArgs}
            toText={toText}
          />
        </form>
      </div>
      <Footer
        handleLinkBtnClick={handleLinkBtnClick}
        handleAnchorTagClick={handleAnchorTagClick}
      />
    </>
  );
}

export default App;
