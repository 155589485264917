export const getLanguageObjByCode = (languageObjects, langCode) => {
  return languageObjects.find((langObj) => {
    return langObj.code === langCode;
  });
};

export const getVoiceModelObjByLangCode = (voiceObjects, langCode) => {
  return voiceObjects.find((voiceObj) => {
    return voiceObj.lang_code === langCode;
  });
};

export const getVoiceModelObjByName = (voiceObjects, modelName) => {
  return voiceObjects.find((voiceObj) => {
    return voiceObj.model === modelName;
  });
};

export const getVoiceModelObjectsByLangCode = (voiceObjects, langCode) => {
  return voiceObjects.filter((voiceObj) => {
    return voiceObj.lang_code === langCode;
  });
};

export const getSpeakerIdxOptions = (voiceObj) => {
  const speakerIdxOptions = [];
  let speakerArray = [];
  if (voiceObj && voiceObj.speaker_array) {
    speakerArray = voiceObj.speaker_array.split("|");
  }
  if (voiceObj && voiceObj.speaker_idx_max) {
    for (let i = 0; i <= voiceObj.speaker_idx_max; i++) {
      const option_obj = {
        value: i,
        label:
          // E.g.: speakerArray[] = ['1-happy', '2-excited', '3-doubly-excited']
          // In order to split speakerArray[i] only on the first occurence of
          // the '-' characer, use code snippet .split(/-(.*)/s) posted by Mark
          // at https://stackoverflow.com/questions/4607745/split-string-only-on-first-instance-of-specified-character)
          voiceObj.speaker_array
            ? "Voice option " +
              (i + 1) +
              " (" +
              speakerArray[i].split(/-(.*)/s)[1] +
              ")"
            : "Voice option " +
              (i + 1) +
              " of " +
              (Number(voiceObj.speaker_idx_max) + 1),
      };
      const idx_default = voiceObj.speaker_idx_default;
      idx_default &&
        idx_default === option_obj.value &&
        !voiceObj.speaker_array &&
        (option_obj.label += " (default)");
      speakerIdxOptions.push(option_obj);
    }
  }
  return speakerIdxOptions;
};
