// Copyright 2024, Grant Richardson
// License: GNU Affero General Public License v3.0 only

// You should have received a copy of the GNU Affero General Public License,
// version 3, along with this program.
// If not, see <https://www.gnu.org/licenses/agpl-3.0.html>.

const ReleaseVersion = window.__ENV__.RELEASE_VERSION;
const GitCommitHash = window.__ENV__.GIT_COMMIT_HASH;
const GitRepoUrl = {
  url: window.__ENV__.GIT_REPO_URL,
};
const agplv3LicenseUrl = "https://choosealicense.com/licenses/agpl-3.0/";
const licensesPageUrl = "licenses.html";

const Footer = ({ handleLinkBtnClick, handleAnchorTagClick }) => {
  return (
    <footer id='footer'>
      <div className='footerItem'>
        {GitCommitHash === "" ? (
          <button
            id='gitRepoLink'
            onClick={() => handleLinkBtnClick(GitRepoUrl.url)}>
            <a
              href={GitRepoUrl.url}
              onClick={(event) => handleAnchorTagClick(event)}
            >
              OpenTranslate v{ReleaseVersion}
            </a>
          </button>
        ) : (
          <button
            id='gitRepoLink'
            onClick={() => handleLinkBtnClick(GitRepoUrl.url)}
          >
            <a
              href={GitRepoUrl.url}
              onClick={(event) => handleAnchorTagClick(event)}
            >
              OpenTranslate v{ReleaseVersion}&ndash;{GitCommitHash}
            </a>
          </button>
        )}
      </div>
      <div className='footerItem'>
        <button
          id='licenseLink'
          onClick={() => handleLinkBtnClick(agplv3LicenseUrl)}
        >
          <a
            href={agplv3LicenseUrl}
            onClick={(event) => handleAnchorTagClick(event)}
          >
            Copyright &copy; 2024
          </a>
        </button>
      </div>
      <div className='footerItem'>
        <button
          id='includedPackagesLink'
          onClick={() => handleLinkBtnClick(licensesPageUrl)}
        >
          <a
            href={licensesPageUrl}
            onClick={(event) => handleAnchorTagClick(event)}
          >
            Included packages
          </a>
        </button>
      </div>
    </footer>
  );
};

export default Footer;
