// Copyright 2024, Grant Richardson
// License: GNU Affero General Public License v3.0 only

// You should have received a copy of the GNU Affero General Public License,
// version 3, along with this program.
// If not, see <https://www.gnu.org/licenses/agpl-3.0.html>.import React from "react";
const BtnsTranslateAndSwap = ({
  mobileOrDesktop,
  handleSubmit,
  handleSwapBtnClick,
  userInputArgs,
  toText,
  btnTranslateRef,
}) => {
  const btnTranslateName = "button-" + mobileOrDesktop + "-go";
  const btnSwapName = "button-" + mobileOrDesktop + "-swap";
  const swapIconName =
    mobileOrDesktop === "mobile" ? "swap-icon-white.svg" : "swap-icon-blue.svg";
  return (
    <div className='button-row'>
      <button
        type='submit'
        id={btnTranslateName}
        name={btnTranslateName}
        className={
          userInputArgs.elementClicked === "btnToSpeak" && !toText
            ? btnTranslateName + " alerts-border"
            : btnTranslateName
        }
        onClick={handleSubmit}
        // Used to invoke btn click in handleTextLangChange of ToLanguageWidget
        ref={btnTranslateRef}
      >
        TRANSLATE
      </button>
      <button
        type='submit'
        name={btnSwapName}
        className={btnTranslateName + " " + btnSwapName}
        onClick={handleSwapBtnClick}
      >
        <img src={swapIconName} alt='swap icon' />
      </button>
    </div>
  );
};
export default BtnsTranslateAndSwap;
