// Copyright 2024, Grant Richardson
// License: GNU Affero General Public License v3.0 only

// You should have received a copy of the GNU Affero General Public License,
// version 3, along with this program.
// If not, see <https://www.gnu.org/licenses/agpl-3.0.html>.

import Selector from "./Selector";
import { useState, useEffect } from "react";
import {
  getVoiceModelObjByLangCode,
  getVoiceModelObjectsByLangCode,
  getVoiceModelObjByName,
  getSpeakerIdxOptions,
} from "../Utils";

// window.__ENV__ variables are configured in the file public/env.js,
// which allows them to be reconfigured post-build, e.g., in production.
const defaultLanguageCode = window.__ENV__.DEFAULT_TO_LANGUAGE_CODE;
const ttsApiUrl = window.__ENV__.TTS_API_URL;
const ttsTimeoutInSec = window.__ENV__.TTS_TIMEOUT_IN_SEC;
const ttsTimeoutInMilliSec = 1000 * ttsTimeoutInSec;
const autoTranslateEnabled = window.__ENV__.ENABLE_AUTO_TRANSLATE;
let unsafeSubstrings = [];
if (window.__ENV__.UNSAFE_SUBSTRINGS) {
  unsafeSubstrings = window.__ENV__.UNSAFE_SUBSTRINGS;
}

const ToLanguageWidget = ({
  availableLanguages,
  userInputArgs,
  setUserInputArgs,
  toText,
  setToText,
  textareaRows,
  btnTranslateRef,
  // Below states are For TTS features
  ttsFeaturesEnabled,
  setIsFetchingData,
  setStatusMessageObj,
  availableVoiceModels,
  selectedToVoiceModelObj,
  setSelectedToVoiceModelObj,
  selectedToSpkIdxObj,
  setSelectedToSpkIdxObj,
  unavailableVoiceObj,
}) => {
  const [toLanguages, setToLanguages] = useState([]);

  const handleTextLangChange = (selectedOption) => {
    var sel = document.getElementById("toLanguageSelector");
    const selToLanguageObj = {
      value: selectedOption.value,
      label: sel.options[sel.selectedIndex].text,
    };
    setUserInputArgs({
      ...userInputArgs,
      toLanguageObj: selToLanguageObj,
    });

    setToText("");

    const voiceLangInTextLangList = getVoiceModelObjByLangCode(
      availableVoiceModels,
      selToLanguageObj.value
    );

    const selVoiceObj = voiceLangInTextLangList
      ? voiceLangInTextLangList
      : unavailableVoiceObj;

    setSelectedToVoiceModelObj(selVoiceObj);

    const spkIdxObjects = getSpeakerIdxOptions(selVoiceObj);
    selVoiceObj.speaker_idx_default
      ? setSelectedToSpkIdxObj(spkIdxObjects[selVoiceObj.speaker_idx_default])
      : setSelectedToSpkIdxObj();

    setTimeout(() => {
      autoTranslateEnabled &&
        userInputArgs.fromText &&
        btnTranslateRef.current?.click();
    }, 2);
  };

  const handleVoiceLangChange = (selectedTarget) => {
    const selVoiceObj = getVoiceModelObjByName(
      availableVoiceModels,
      selectedTarget.value // == selVoiceObj.model (e.g. en_US-ljspeech-high)
    );

    setSelectedToVoiceModelObj(selVoiceObj);

    const spkIdxObjects = getSpeakerIdxOptions(selVoiceObj);
    selVoiceObj.speaker_idx_default
      ? setSelectedToSpkIdxObj(spkIdxObjects[selVoiceObj.speaker_idx_default])
      : setSelectedToSpkIdxObj();
  };

  const handleSpeakerIdxChange = (selectedTarget) => {
    const spkIdxObjects = getSpeakerIdxOptions(selectedToVoiceModelObj);
    setSelectedToSpkIdxObj(spkIdxObjects[selectedTarget.value]);
  };

  const handleToSpeakClick = async (e) => {
    e.preventDefault();
    setStatusMessageObj({
      messageText: "",
      isVisible: false,
      passOrFail: "pass",
    });
    if (!validateToText(e)) {
      return;
    }
    try {
      setIsFetchingData(true);
      await fetchAndPlayTtsAudio();
      setIsFetchingData(false);
    } catch (error) {
      setStatusMessageObj({
        messageText:
          "Failed to fetch speech audio! Error response message: " + error,
        isVisible: true,
        passOrFail: "fail",
      });
    }
  };

  // Validate output text (aka 'toText') upon clicking btnToSpeak TTS button
  const validateToText = (e) => {
    let isInvalidToText = false;
    let isInvalidToLanguage = false;
    let elementId = e.target.id;
    // Invalidate empty toText
    !toText && (isInvalidToText = true);
    !userInputArgs.toLanguageObj.value && (isInvalidToLanguage = true);
    isInvalidToText &&
      elementId === "btnToSpeak" &&
      setStatusMessageObj({
        messageText:
          "Speech request not sent. Please translate some input text first.",
        isVisible: true,
        passOrFail: "fail",
      });
    // Invalidate toText if it contains any prohibited (unsafe) substrings
    toText &&
      unsafeSubstrings.some((item) => toText.includes(item)) &&
      (isInvalidToText = true) &&
      setStatusMessageObj({
        messageText:
          'Speech request not sent. Output translation contains one or more of these potentially unsafe substrings: "' +
          unsafeSubstrings.join('", "') +
          '"',
        isVisible: true,
        passOrFail: "fail",
      });
    !isInvalidToText &&
      setStatusMessageObj({
        messageText: "",
        isVisible: false,
        passOrFail: "pass",
      });
    setUserInputArgs({
      ...userInputArgs,
      isInvalidFromText: !userInputArgs.fromText,
      isInvalidToLanguage: isInvalidToLanguage,
      elementClicked: elementId,
    });
    return !isInvalidToText;
  };

  // Snippet from answer by Endless, posted on 2018-04-30 at:
  // https://stackoverflow.com/questions/46946380/fetch-api-request-timeout
  AbortSignal.timeout ??= function timeout(ms) {
    const ctrl = new AbortController();
    setTimeout(() => ctrl.abort(), ms);
    return ctrl.signal;
  };

  const fetchAndPlayTtsAudio = async () => {
    let reqBody = {
      input_text: toText,
      voice_model: selectedToVoiceModelObj.model,
      // speed: userInputArgs.speed,
    };
    selectedToSpkIdxObj &&
      selectedToSpkIdxObj.value &&
      (reqBody = { ...reqBody, speaker_idx: selectedToSpkIdxObj.value });
    try {
      const response = await fetch(ttsApiUrl + "/tts", {
        signal: AbortSignal.timeout(ttsTimeoutInMilliSec),
        method: "POST",
        body: JSON.stringify(reqBody),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      if (response.status === 200) {
        const bufferResponse = await response.arrayBuffer();
        const audioCtx = new AudioContext();
        // await is required here
        const decodedAudio = await audioCtx.decodeAudioData(bufferResponse);
        const ctxBufferSource = audioCtx.createBufferSource();
        ctxBufferSource.buffer = decodedAudio;
        ctxBufferSource.connect(audioCtx.destination);
        // play the audio
        ctxBufferSource.start();
      } else {
        const jsonResponse = await response.json();
        let errorMessage = "";
        response.status === 422
          ? (errorMessage =
              ' Error: "' +
              jsonResponse.detail[0].msg +
              '" Error location: ' +
              jsonResponse.detail[0].loc[1])
          : (errorMessage = jsonResponse.detail);
        setStatusMessageObj({
          messageText:
            "Failed to fetch speech audio. " +
            updateErrorMsgWording(errorMessage),
          isVisible: true,
          passOrFail: "fail",
        });
      }
    } catch (error) {
      setStatusMessageObj({
        messageText:
          "Failed to fetch speech audio. " + updateErrorMsgWording(error),
        isVisible: true,
        passOrFail: "fail",
      });
    }
  };

  const updateErrorMsgWording = (errorMessage) => {
    errorMessage = errorMessage.toString().replace("TypeError: ", "");
    if (errorMessage.includes("The user aborted a request")) {
      errorMessage =
        "Your request was cancelled after waiting " +
        (parseFloat(ttsTimeoutInSec) === 1
          ? "one second."
          : ttsTimeoutInSec + " seconds.");
    }
    if (errorMessage.includes("Too Many Requests")) {
      errorMessage = "Too many requests. Please wait a moment and try again.";
    }
    return errorMessage;
  };

  useEffect(() => {
    setToLanguages(availableLanguages);

    setUserInputArgs({
      ...userInputArgs,
      toLanguageObj: { value: defaultLanguageCode },
    });

    if (ttsFeaturesEnabled) {
      // Try to find a default voice model for any selected toText language.
      if (userInputArgs.toLanguageObj) {
        const voiceLangInTextLangList = getVoiceModelObjByLangCode(
          availableVoiceModels,
          userInputArgs.toLanguageObj.value
        );
        voiceLangInTextLangList &&
          setSelectedToVoiceModelObj(voiceLangInTextLangList);
      }
    }
  }, [availableLanguages]); // eslint-disable-line

  return (
    <div className='languageBlock'>
      <h3>Output translation:</h3>
      <Selector
        id='toLanguageSelector'
        className={
          (userInputArgs.elementClicked === "btnToSpeak" &&
            userInputArgs.isInvalidToLanguage &&
            !toText) ||
          (userInputArgs.elementClicked === "button-mobile-go" &&
            userInputArgs.isInvalidToLanguage) ||
          (userInputArgs.elementClicked === "button-desktop-go" &&
            userInputArgs.isInvalidToLanguage)
            ? "selector alerts-border"
            : "selector"
        }
        options={toLanguages}
        value={userInputArgs.toLanguageObj && userInputArgs.toLanguageObj.value}
        onChange={handleTextLangChange}
        placeholder='Output language...'
      />
      <textarea
        // id='toTextarea'
        readOnly
        value={toText}
        className='toTextarea'
        name='toText'
        cols='40'
        rows={textareaRows}
      />
      {ttsFeaturesEnabled && (
        <Selector
          id='toVoiceLangSelector'
          className={
            selectedToVoiceModelObj.lang_code !== "xx"
              ? "selector"
              : "selector empty"
          }
          options={
            !userInputArgs.toLanguageObj.value
              ? availableVoiceModels.map((voiceObj) => {
                  // No toLanguage selected, so return all voice models
                  return {
                    value: voiceObj.model,
                    label:
                      // Concatenate the language,
                      // e.g., "English (English, Great Britain)"
                      voiceObj.language +
                      " " +
                      // with the 7th char onwards (after first hyphen) of the
                      // model name, e.g., "en_GB-alba-medium" --> "alba-medium"
                      voiceObj.model.slice(6),
                    // to return "English (English, Great Britain) alba-medium".
                  };
                })
              : getVoiceModelObjectsByLangCode(
                  availableVoiceModels,
                  userInputArgs.toLanguageObj.value
                ).map((voiceObj) => {
                  // : toLanguage selected, so return its related voice models
                  return {
                    value: voiceObj.model,
                    label:
                      // Concatenate the language,
                      // e.g., "English (English, Great Britain)"
                      voiceObj.language +
                      " " +
                      // with the 7th char onwards (after first hyphen) of the
                      // model name, e.g., "en_GB-alba-medium" --> "alba-medium"
                      voiceObj.model.slice(6),
                    // to return "English (English, Great Britain) alba-medium".
                  };
                })
          }
          value={selectedToVoiceModelObj && selectedToVoiceModelObj.model}
          onChange={handleVoiceLangChange}
          placeholder={
            selectedToVoiceModelObj &&
            // selectedToVoiceModelObj.lang_code == "xx" (unavailable lang_code)
            selectedToVoiceModelObj.lang_code === unavailableVoiceObj.lang_code
              ? userInputArgs.toLanguageObj.label +
                " has no available voice model"
              : "Text-to-speech voice model..."
          }
          // Disable if only 1 voice model available for selected text language
          isDisabled={
            userInputArgs.toLanguageObj &&
            userInputArgs.toLanguageObj.value &&
            getVoiceModelObjectsByLangCode(
              availableVoiceModels,
              userInputArgs.toLanguageObj.value
            ).filter(
              (voiceObj) =>
                voiceObj.lang_code === userInputArgs.toLanguageObj.value
            ).length === 1
          }
        />
      )}
      {selectedToVoiceModelObj.lang_code &&
        selectedToVoiceModelObj.lang_code !== "xx" && (
          <button
            type='submit'
            id='btnToSpeak'
            name='btnToSpeak'
            className='button-speak'
            onClick={handleToSpeakClick}
          >
            <img src='talk_icon.svg' alt='speak icon' width='26' />
          </button>
        )}
      {ttsFeaturesEnabled &&
        selectedToVoiceModelObj &&
        selectedToVoiceModelObj.speaker_idx_max && (
          <Selector
            id='toVoiceSpkIdxSelector'
            className='selector'
            options={getSpeakerIdxOptions(selectedToVoiceModelObj)}
            value={selectedToSpkIdxObj && selectedToSpkIdxObj.value}
            onChange={handleSpeakerIdxChange}
            placeholder={"Select a voice option..."}
          />
        )}
    </div>
  );
};

export default ToLanguageWidget;
