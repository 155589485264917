// Copyright 2024, Grant Richardson
// License: GNU Affero General Public License v3.0 only

// You should have received a copy of the GNU Affero General Public License,
// version 3, along with this program.
// If not, see <https://www.gnu.org/licenses/agpl-3.0.html>.
const Selector = ({
  id,
  options,
  value,
  onChange,
  isDisabled = false,
  placeholder,
  className,
}) => {
  return (
    <div>
      <select
        className={
          //To dim when disabled in FF and to reduce vertical padding when displaying Chinese characters in FF
          isDisabled
            ? value === "zh_CN-huayan-x_low"
              ? className + " selector--chinese-characters selector--disabled"
              : className + " selector--disabled"
            : value === "zh_CN-huayan-x_low"
            ? className + " selector--chinese-characters"
            : className
        }
        id={id}
        value={value}
        onChange={(e) => onChange(e.target)}
        disabled={isDisabled}
      >
        {!value && <option value=''>{placeholder}</option>}
        {options.map((option, idx) => {
          return (
            <option key={idx} value={option.value}>
              {option.label}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default Selector;
