// Copyright 2024, Grant Richardson
// License: GNU Affero General Public License v3.0 only

// You should have received a copy of the GNU Affero General Public License,
// version 3, along with this program.
// If not, see <https://www.gnu.org/licenses/agpl-3.0.html>.

import Selector from "./Selector";
import { useState, useEffect } from "react";
import {
  getVoiceModelObjByLangCode,
  getVoiceModelObjectsByLangCode,
  getVoiceModelObjByName,
  getSpeakerIdxOptions,
} from "../Utils";

// window.__ENV__ variables are configured in the file public/env.js,
// which allows them to be reconfigured post-build, e.g., in production.
const defaultLanguageCode = window.__ENV__.DEFAULT_FROM_LANGUAGE_CODE;
const ttsApiUrl = window.__ENV__.TTS_API_URL;
const ttsTimeoutInSec = window.__ENV__.TTS_TIMEOUT_IN_SEC;
const ttsTimeoutInMilliSec = 1000 * ttsTimeoutInSec;
let unsafeSubstrings = [];
if (window.__ENV__.UNSAFE_SUBSTRINGS) {
  unsafeSubstrings = window.__ENV__.UNSAFE_SUBSTRINGS;
}

const FromLanguageWidget = ({
  availableLanguages,
  userInputArgs,
  setUserInputArgs,
  textareaRows,
  // Below props are For TTS features
  ttsFeaturesEnabled,
  setIsFetchingData,
  setStatusMessageObj,
  availableVoiceModels,
  selectedFromVoiceModelObj,
  setSelectedFromVoiceModelObj,
  selectedFromSpkIdxObj,
  setSelectedFromSpkIdxObj,
  unavailableVoiceObj,
}) => {
  const [fromLanguages, setFromLanguages] = useState([]);

  const handleTextLangChange = (selectedTarget) => {
    var sel = document.getElementById("fromLanguageSelector");
    const selFromLanguageObj = {
      value: selectedTarget.value,
      label: sel.options[sel.selectedIndex].text,
    };
    setUserInputArgs({
      ...userInputArgs,
      fromLanguageObj: selFromLanguageObj,
    });

    const voiceLangInTextLangList = getVoiceModelObjByLangCode(
      availableVoiceModels,
      selFromLanguageObj.value
    );

    const selVoiceObj = voiceLangInTextLangList
      ? voiceLangInTextLangList
      : unavailableVoiceObj;

    setSelectedFromVoiceModelObj(selVoiceObj);

    const spkIdxObjects = getSpeakerIdxOptions(selVoiceObj);
    selVoiceObj.speaker_idx_default
      ? setSelectedFromSpkIdxObj(spkIdxObjects[selVoiceObj.speaker_idx_default])
      : setSelectedFromSpkIdxObj();
  };

  const handleVoiceLangChange = (selectedTarget) => {
    const selVoiceObj = getVoiceModelObjByName(
      availableVoiceModels,
      selectedTarget.value // Voice model name
    );

    setSelectedFromVoiceModelObj(selVoiceObj);

    const spkIdxObjects = getSpeakerIdxOptions(selVoiceObj);
    selVoiceObj.speaker_idx_default
      ? setSelectedFromSpkIdxObj(spkIdxObjects[selVoiceObj.speaker_idx_default])
      : setSelectedFromSpkIdxObj();
  };

  const handleTextOnChange = (event) => {
    setUserInputArgs({ ...userInputArgs, fromText: event.target.value });
  };

  const handleSpeakerIdxChange = (selectedTarget) => {
    const spkIdxObjects = getSpeakerIdxOptions(selectedFromVoiceModelObj);
    setSelectedFromSpkIdxObj(spkIdxObjects[selectedTarget.value]);
  };

  const handleFromSpeakClick = async (e) => {
    e.preventDefault();
    setStatusMessageObj({
      messageText: "",
      isVisible: false,
      passOrFail: "pass",
    });
    if (!validateFromText(e)) {
      return;
    }
    try {
      setIsFetchingData(true);
      await fetchAndPlayTtsAudio();
      setIsFetchingData(false);
    } catch (error) {
      setStatusMessageObj({
        messageText:
          "Failed to fetch speech audio! Error response message: " + error,
        isVisible: true,
        passOrFail: "fail",
      });
    }
  };

  // Validate input text (aka 'fromText') upon clicking btnFromSpeak TTS button
  const validateFromText = (e) => {
    let isInvalidFromText = false;
    let fromText = userInputArgs.fromText;
    // Invalidate empty fromText
    !fromText &&
      (isInvalidFromText = true) &&
      setStatusMessageObj({
        messageText:
          "Speech request not sent. Please enter some input text to convert to speech.",
        isVisible: true,
        passOrFail: "fail",
      });
    //  Invalidate fromText if it contains any prohibited (unsafe) substrings for TTS
    fromText &&
      unsafeSubstrings.some((item) => fromText.includes(item)) &&
      (isInvalidFromText = true) &&
      setStatusMessageObj({
        messageText:
          'Speech request not sent. Input text contains one or more of these potentially unsafe substrings: "' +
          unsafeSubstrings.join('", "') +
          '"',
        isVisible: true,
        passOrFail: "fail",
      });
    !isInvalidFromText &&
      setStatusMessageObj({
        messageText: "",
        isVisible: false,
        passOrFail: "pass",
      });
    setUserInputArgs({
      ...userInputArgs,
      isInvalidFromText: isInvalidFromText,
      elementClicked: e.target.id,
    });
    return !isInvalidFromText;
  };

  // Snippet from answer by Endless, posted on 2018-04-30 at:
  // https://stackoverflow.com/questions/46946380/fetch-api-request-timeout
  AbortSignal.timeout ??= function timeout(ms) {
    const ctrl = new AbortController();
    setTimeout(() => ctrl.abort(), ms);
    return ctrl.signal;
  };

  const fetchAndPlayTtsAudio = async () => {
    let reqBody = {
      input_text: userInputArgs.fromText,
      voice_model: selectedFromVoiceModelObj.model,
      // speed: userInputArgs.speed,
    };
    selectedFromSpkIdxObj &&
      selectedFromSpkIdxObj.value &&
      (reqBody = { ...reqBody, speaker_idx: selectedFromSpkIdxObj.value });
    try {
      const response = await fetch(ttsApiUrl + "/tts", {
        signal: AbortSignal.timeout(ttsTimeoutInMilliSec),
        method: "POST",
        body: JSON.stringify(reqBody),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      if (response.status === 200) {
        const bufferResponse = await response.arrayBuffer();
        const audioCtx = new AudioContext();
        // await is required here
        const decodedAudio = await audioCtx.decodeAudioData(bufferResponse);
        const ctxBufferSource = audioCtx.createBufferSource();
        ctxBufferSource.buffer = decodedAudio;
        ctxBufferSource.connect(audioCtx.destination);
        // play the audio
        ctxBufferSource.start();
      } else {
        const jsonResponse = await response.json();
        let errorMessage = "";
        response.status === 422
          ? (errorMessage =
              ' Error: "' +
              jsonResponse.detail[0].msg +
              '" Error location: ' +
              jsonResponse.detail[0].loc[1])
          : (errorMessage = jsonResponse.detail);
        setStatusMessageObj({
          messageText:
            "Failed to fetch speech audio. " +
            updateErrorMsgWording(errorMessage),
          isVisible: true,
          passOrFail: "fail",
        });
      }
    } catch (error) {
      setStatusMessageObj({
        messageText:
          "Failed to fetch speech audio. " + updateErrorMsgWording(error),
        isVisible: true,
        passOrFail: "fail",
      });
    }
  };

  const updateErrorMsgWording = (errorMessage) => {
    errorMessage = errorMessage.toString().replace("TypeError: ", "");
    if (errorMessage.includes("The user aborted a request")) {
      errorMessage =
        "Your request was cancelled after waiting " +
        (parseFloat(ttsTimeoutInSec) === 1
          ? "one second."
          : ttsTimeoutInSec + " seconds.");
    }
    if (errorMessage.includes("Too Many Requests")) {
      errorMessage = "Too many requests. Please wait a moment and try again.";
    }
    return errorMessage;
  };

  useEffect(() => {
    setFromLanguages(availableLanguages);

    setUserInputArgs({
      ...userInputArgs,
      fromLanguageObj: { value: defaultLanguageCode },
    });

    if (ttsFeaturesEnabled) {
      // Try to find a default voice model for any selected fromText language.
      if (userInputArgs.fromLanguageObj) {
        const voiceLangInTextLangList = getVoiceModelObjByLangCode(
          availableVoiceModels,
          userInputArgs.fromLanguageObj.value
        );
        voiceLangInTextLangList &&
          setSelectedFromVoiceModelObj(voiceLangInTextLangList);
      }
    }
  }, [availableLanguages, availableVoiceModels]); // eslint-disable-line

  return (
    <div className='languageBlock'>
      <h3>Input text:</h3>
      <Selector
        id='fromLanguageSelector'
        className='selector'
        options={fromLanguages}
        value={
          userInputArgs.fromLanguageObj && userInputArgs.fromLanguageObj.value
        }
        onChange={handleTextLangChange}
        isDisabled={false}
        placeholder='Input language... (auto)'
      />
      <textarea
        id='fromTextarea'
        value={userInputArgs.fromText} // causes rerender on swap from toText
        onChange={handleTextOnChange}
        className={
          userInputArgs.isInvalidFromText
            ? "fromTextarea alerts-border"
            : "fromTextarea"
        }
        autoFocus={userInputArgs.isInvalidFromText}
        name='fromText'
        cols='40'
        rows={textareaRows}
        placeholder='Enter your input text here...'
      />
      {ttsFeaturesEnabled && (
        <Selector
          id='fromVoiceLangSelector'
          className={
            selectedFromVoiceModelObj.lang_code !== "xx"
              ? "selector"
              : "selector empty"
          }
          options={
            !userInputArgs.fromLanguageObj.value
              ? availableVoiceModels.map((voiceObj) => {
                  return {
                    value: voiceObj.model,
                    label:
                      // Concatenate the language,
                      // e.g., "English (English, Great Britain)"
                      voiceObj.language +
                      " " +
                      // with the 7th char onwards (after first hyphen) of the
                      // model name, e.g., "en_GB-alba-medium" --> "alba-medium"
                      voiceObj.model.slice(6),
                    // to return "English (English, Great Britain) alba-medium".
                  };
                })
              : getVoiceModelObjectsByLangCode(
                  availableVoiceModels,
                  userInputArgs.fromLanguageObj.value
                ).map((voiceObj) => {
                  return {
                    value: voiceObj.model,
                    label:
                      // Concatenate the language,
                      // e.g., "English (English, Great Britain)"
                      voiceObj.language +
                      " " +
                      // with the 7th char onwards (after first hyphen) of the
                      // model name, e.g., "en_GB-alba-medium" --> "alba-medium"
                      voiceObj.model.slice(6),
                    // to return "English (English, Great Britain) alba-medium".
                  };
                })
          }
          value={selectedFromVoiceModelObj && selectedFromVoiceModelObj.model}
          onChange={handleVoiceLangChange}
          placeholder={
            selectedFromVoiceModelObj &&
            selectedFromVoiceModelObj.lang_code ===
              unavailableVoiceObj.lang_code
              ? userInputArgs.fromLanguageObj.label +
                " has no available voice model"
              : "Text-to-speech voice model..."
          }
          // Disable if only 1 voice model available for selected text language
          isDisabled={
            userInputArgs.fromLanguageObj &&
            userInputArgs.fromLanguageObj.value &&
            getVoiceModelObjectsByLangCode(
              availableVoiceModels,
              userInputArgs.fromLanguageObj.value
            ).filter(
              (voiceObj) =>
                voiceObj.lang_code === userInputArgs.fromLanguageObj.value
            ).length === 1
          }
        />
      )}
      {selectedFromVoiceModelObj.lang_code &&
        selectedFromVoiceModelObj.lang_code !== "xx" && (
          <button
            type='submit'
            id='btnFromSpeak'
            name='btnFromSpeak'
            className='button-speak'
            onClick={handleFromSpeakClick}
          >
            <img src='talk_icon.svg' alt='speak icon' width='26' />
          </button>
        )}
      {ttsFeaturesEnabled &&
        selectedFromVoiceModelObj &&
        selectedFromVoiceModelObj.speaker_idx_max && (
          <Selector
            id='fromVoiceSpkIdxSelector'
            className='selector'
            options={getSpeakerIdxOptions(selectedFromVoiceModelObj)}
            value={selectedFromSpkIdxObj && selectedFromSpkIdxObj.value}
            onChange={handleSpeakerIdxChange}
            placeholder={"Select a voice option..."}
          />
        )}
    </div>
  );
};

export default FromLanguageWidget;
